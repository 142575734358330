import React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import variables from '../../../utils/variables';
import { connect } from 'react-redux';
import './index.css';
import { hideConnectDialog } from '../../../actions/navbar';
import KeplrButton from './KeplrButton';
import CosmostationConnectButton from './CosmostationConnectButton';
import LeapConnectButton from './LeapConnectButton';
import { ReactComponent as DownloadIcon } from '../../../assets/navbar/download.svg';
import { Button } from '@mui/material';
import { walletExtensions } from '../../../config';
import MetaMaskButton from './MetaMaskButton';

const ConnectDialog = (props) => {
    const handleDownloadWallet = (event, wallet) => {
        event.stopPropagation();
        if (wallet === 'keplr') {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                // Take the user to a different screen here.
                window.open('keplrwallet://wcV1');
            } else if (/Android/i.test(navigator.userAgent)) {
                window.open('intent://wcV1#Intent;package=com.chainapsis.keplr;scheme=keplrwallet;end;');
            } else {
                window.open(walletExtensions.KEPLR);
            }
        } else if (wallet === 'cosmostation') {
            window.open(walletExtensions.COSMO_STATION);
        } else if (wallet === 'leap') {
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                window.open('https://leapcosmoswallet.page.link/cy9w13Sfg5LhRvfL8');
            } else {
                window.open(walletExtensions.LEAP);
            }
        } else if (wallet === 'metamask') {
            window.open(walletExtensions.META_MASK);
        }
    };

    return (
        <Dialog
            aria-describedby="connect with OmniFlix marketplace"
            aria-labelledby="Connect account"
            className="dialog connect_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="connect_dialog_content">
                <h2 className="title">{variables[props.lang]['connect_wallet']}</h2>
                <div className="wallets_section">
                    <div className="wallet_button">
                        <MetaMaskButton/>
                        <Button
                            className="download_button"
                            onClick={(e) => handleDownloadWallet(e, 'metamask')}>
                            <DownloadIcon/>
                        </Button>
                    </div>
                    <div className="wallet_button">
                        <KeplrButton/>
                        <Button
                            className="download_button"
                            onClick={(e) => handleDownloadWallet(e, 'keplr')}>
                            <DownloadIcon/>
                        </Button>
                    </div>
                    <div className="wallet_button">
                        <CosmostationConnectButton/>
                        <Button
                            className="download_button"
                            onClick={(e) => handleDownloadWallet(e, 'cosmostation')}>
                            <DownloadIcon/>
                        </Button>
                    </div>
                    <div className="wallet_button">
                        <LeapConnectButton/>
                        <Button
                            className="download_button"
                            onClick={(e) => handleDownloadWallet(e, 'leap')}>
                            <DownloadIcon/>
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

ConnectDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.navbar.connectDialog,
    };
};

const actionToProps = {
    handleClose: hideConnectDialog,
};

export default connect(stateToProps, actionToProps)(ConnectDialog);
