import React from 'react';
import { Button, Drawer } from '@mui/material';
import './index.css';
import * as PropTypes from 'prop-types';
import { setDisconnect } from '../../../actions/account';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import CopyButton from '../../../components/CopyButton';
import { commaSeparator, splitDecimals } from '../../../utils/numbers';
import IBCTokens from './IBCTokens';
import { showDepositeDialog, showWithdrawDialog } from '../../../actions/myAccount';
import DepositeDialog from '../../MyAccount/IBCTokens/DepositeDialog';
import WithDrawDialog from '../../MyAccount/IBCTokens/WithDrawDialog';
import flixToken from '../../../assets/tokens/flix.svg';
import { config } from '../../../config';
import { fetchFaucetClaim, fetchIBCBalanceList, showFaucetDialog } from '../../../actions/faucet';
import { connectIBCAccount } from '../../../actions/account/IBCTokens';
import refreshIcon from '../../../assets/refresh.svg';
import { fetchBalance } from '../../../actions/account/BCDetails';
// import FeeGrant from './FeeGrant';
import '../../MyAccount/IBCTokens/index.css';
import { formatCount } from '../../../utils/price';
import { ReactComponent as RightArrow } from '../../../assets/my-account/arrow_right.svg';
import { getWrapAddress } from '../../../utils/strings';
import withRouter from '../../../components/WithRouter';

class SidePanel extends React.Component {
    constructor (props) {
        super(props);

        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleFaucet = this.handleFaucet.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleRedirectAccount = this.handleRedirectAccount.bind(this);
    }

    handleRefresh () {
        if (this.props.address) {
            this.props.fetchBalance(this.props.address);
        }
    }

    handleDisconnect () {
        this.props.onClose();
        this.props.setDisconnect();
        localStorage.removeItem('of_market_address');
        localStorage.removeItem('of_market_cosmostation');
        localStorage.removeItem('of_market_leap');
        localStorage.removeItem('of_market_metamask');
    }

    handleRedirect (value) {
        this.props.onClose();
        this.props.router.navigate(`/account/${this.props.address}/${value}`);
    }

    handleRedirectAccount () {
        this.props.onClose();
        this.props.router.navigate(`/account/${this.props.address}`);
    }

    handleFaucet () {
        // const list = [...FaucetList];

        if (this.props.faucetClaim && !this.props.faucetClaim.claim && !this.props.faucetClaimInProgress) {
            this.props.fetchFaucetClaim(this.props.address);
        }

        // if (this.props.balanceList && !this.props.balanceList.length && !this.props.balanceListInProgress) {
        //     list.map((value) => {
        //         const config = {
        //             RPC_URL: value && value.network && value.network.rpc_address,
        //             REST_URL: value && value.network && value.network.api_address,
        //             CHAIN_ID: value && value.network && value.network.chain_id,
        //             CHAIN_NAME: value && value.network && value.network.name,
        //             COIN_DENOM: value && value.network && value.network.display_denom,
        //             COIN_MINIMAL_DENOM: value && value.network && value.network.denom,
        //             COIN_DECIMALS: value && value.network && value.network.decimals,
        //             PREFIX: value && value.network && value.network.address_prefix,
        //         };
        //
        //         this.props.connectIBCAccount(config, (address) => {
        //             this.props.fetchIBCBalanceList(config.REST_URL, address[0].address);
        //         });
        //
        //         return null;
        //     });
        // }

        this.props.showFaucetDialog();
        this.props.onClose();
    }

    render () {
        const denom = this.props.chain && this.props.chain.ibc_denom_hash
            ? this.props.chain.ibc_denom_hash
            : config.COIN_MINIMAL_DENOM;
        const decimals = this.props.chain && this.props.chain.network && this.props.chain.network.decimals
            ? this.props.chain.network.decimals
            : config.COIN_DECIMALS;
        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

        let balance = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === denom);
        if (balance > 100) {
            balance = formatCount(balance, true);
        }

        balance = balance && balance.amount && splitDecimals(balance.amount / (10 ** decimals));
        return (
            <Drawer
                anchor="right"
                className="side_panel"
                open={this.props.open}
                onClose={this.props.onClose}>
                <div className="side_panel_content scroll_bar">
                    <div className="network account_section1">
                        <div className="row1">
                            <div className="left">
                                <p>
                                    <img alt="icon" src={flixToken}/>
                                    OmniFlix Network
                                </p>
                                <div className="copy_div">
                                    <div className="hash_text" title={this.props.address}>
                                        {getWrapAddress(this.props.address, 10, 6)}
                                    </div>
                                    <CopyButton data={this.props.address}/>
                                </div>
                            </div>
                            <div className="right">
                                <img alt="icon" src={flixToken}/>
                                <span>
                                    {balance && balance.length
                                        ? <>
                                            {balance.length && balance[0] && commaSeparator(balance[0])}
                                            {balance.length && balance[1] &&
                                                <span>.{balance.length && balance[1]}</span>}
                                        </>
                                        : 0}{'  '}
                                    {(ibcToken && ibcToken.network && ibcToken.network.display_denom) || config.COIN_DENOM}&nbsp;
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="my_account network">
                        <div className="header">
                            <div className="heading_left">
                                <h3>{variables[this.props.lang].my_account}</h3>
                                <img
                                    alt="refresh"
                                    src={refreshIcon}
                                    onClick={() => this.handleRefresh()}/>
                            </div>
                            <div className="heading_right">
                                <Button
                                    className="primary_button"
                                    onClick={this.handleFaucet}>
                                    <span>{variables[this.props.lang].claim}</span>
                                </Button>
                                {/* <Button className="redirect" onClick={this.handleRedirectAccount}> */}
                                {/*     <RightArrow/> */}
                                {/* </Button> */}
                            </div>
                        </div>
                        <div className="content">
                            <div className="account_list">
                                <div
                                    className="section"
                                    onClick={() => this.handleRedirect('nfts')}>
                                    <p>
                                        {variables[this.props.lang].nfts}{' '}
                                        {' ' + (this.props.myAccountStatsData && this.props.myAccountStatsData.total_nfts
                                            ? `(${this.props.myAccountStatsData && this.props.myAccountStatsData.total_nfts})` : '')}
                                    </p>
                                    <Button onClick={() => this.handleRedirect('nfts')}>
                                        <RightArrow/>
                                    </Button>
                                </div>
                                <div
                                    className="section"
                                    onClick={() => this.handleRedirect('collections')}>
                                    <p>
                                        {variables[this.props.lang].collections}{' '}
                                        {' ' + (this.props.myAccountStatsData && this.props.myAccountStatsData.total_collections
                                            ? `(${this.props.myAccountStatsData && this.props.myAccountStatsData.total_collections})` : '')}
                                    </p>
                                    <Button onClick={() => this.handleRedirect('collections')}>
                                        <RightArrow/>
                                    </Button>
                                </div>
                                <div
                                    className="section"
                                    onClick={() => this.handleRedirect('tokens')}>
                                    <p>{variables[this.props.lang].tokens}</p>
                                    <Button onClick={() => this.handleRedirect('tokens')}>
                                        <RightArrow/>
                                    </Button>
                                </div>
                                <div
                                    className="section"
                                    onClick={() => this.handleRedirect('activity')}>
                                    <p>{variables[this.props.lang].activity}</p>
                                    <Button onClick={() => this.handleRedirect('activity')}>
                                        <RightArrow/>
                                    </Button>
                                </div>
                                <div className="footer">
                                    <Button className="disconnect_button" onClick={this.handleDisconnect}>
                                        {variables[this.props.lang].disconnect}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="recently_collected ibc_tokens">
                        <IBCTokens onClose={this.props.onClose}/>
                        <DepositeDialog/>
                        <WithDrawDialog/>
                    </div>
                    {/* <FeeGrant/> */}
                </div>
                <DepositeDialog/>
                <WithDrawDialog/>
            </Drawer>
        );
    }
}

SidePanel.propTypes = {
    address: PropTypes.string.isRequired,
    balance: PropTypes.array.isRequired,
    balanceList: PropTypes.array.isRequired,
    balanceListInProgress: PropTypes.bool.isRequired,
    chain: PropTypes.object.isRequired,
    connectIBCAccount: PropTypes.func.isRequired,
    faucetClaim: PropTypes.object.isRequired,
    faucetClaimInProgress: PropTypes.bool.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchFaucetClaim: PropTypes.func.isRequired,
    fetchIBCBalanceList: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    keys: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    myAccountStatsData: PropTypes.object.isRequired,
    myAccountStatsInProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }).isRequired,
    setDisconnect: PropTypes.func.isRequired,
    showDepositeDialog: PropTypes.func.isRequired,
    showFaucetDialog: PropTypes.func.isRequired,
    showWithdrawDialog: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        balance: state.account.bc.balance.value,
        balanceList: state.faucet.balanceList.value,
        balanceListInProgress: state.faucet.balanceList.inProgress,
        chain: state.account.chainID.value,
        keys: state.account.wallet.connection.keys,
        ibcTokensList: state.marketplace.ibcTokensList.value,
        faucetClaim: state.faucet.faucetClaim.value,
        faucetClaimInProgress: state.faucet.faucetClaim.inProgress,
        lang: state.language,
        myAccountStatsData: state.myAccount.stats.result,
        myAccountStatsInProgress: state.myAccount.stats.inProgress,
    };
};

const actionToProps = {
    setDisconnect,
    showDepositeDialog,
    showWithdrawDialog,
    fetchFaucetClaim,
    showFaucetDialog,
    fetchIBCBalanceList,
    connectIBCAccount,
    fetchBalance,
};

export default withRouter(connect(stateToProps, actionToProps)(SidePanel));
